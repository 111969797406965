import { useEffect } from 'react';
import { useAnalytics } from '@/hooks/analytics';
import type { ErrorProps } from '../app/global-error';

function ErrorComponent({ reset, error }: ErrorProps): JSX.Element {
  const { trackError } = useAnalytics();

  useEffect(() => {
    trackError(error, true);
  }, [error]);

  return (
    <div className='min-h-[calc(100vh-180px)]'>
      <div className='mx-auto max-w-prose text-center'>
        <h2 className='pt-10 text-2xl'>Something went wrong.</h2>
        You can{' '}
        <button className='text-red-500' onClick={() => reset()} type='submit'>
          try again
        </button>{' '}
        or reload the page.
        <p className='pt-4'>
          If the problem persists, contact membership services (
          <a className='text-red-500' href='mailto:support@whoop.com'>
            support@whoop.com
          </a>
          ) or join WHOOP through another method, such as{' '}
          <a
            className='text-red-500'
            href='https://www.amazon.com/'
            rel='noopener'
            target='_blank'
          >
            Amazon.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}

export default ErrorComponent;
